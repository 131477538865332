import * as React from 'react'
import Layout from '../../../layout'
import CreateTeamPage from '../../../pagesComponents/groups/create-team'

const CreateTeam = ({ id }) => {
	return (
		<Layout title="Create Team">
			<CreateTeamPage id={id} />
		</Layout>
	)
}

export default CreateTeam
